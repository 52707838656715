.nav-pills {
  @include media-breakpoint-down(md) {
    overflow: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
    font-size: 12px !important;
    background-color: $gray-100;
  }
  .nav-link {
    // background-color: $gray-100 ;
    font-weight: $font-weight-semibold;
    &.active {
      background-color: $white;
      color: $gray-900;
    }
    @include media-breakpoint-down(md) {
      padding: rem(12) 0;
      position: relative;
      &.active {
        background-color: transparent;
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 3px;
          left: 0;
          bottom: 0;
          background-color: $primary;
        }
      }
    }
  }
}

.tab-underline {
  .nav-item {
    .nav-link {
      position: relative;
      color: #2E2D2C;
      &::before {
        content: '';
        width: 100%;
        height: 5px;
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: -1px;
        background-color: $primary;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
      }
      &.active {
        &::before {
          transform: scale(1);
        }
      }
    }
  }
}
