@import 'custom_variables/variables';
@import 'bootstrap';
@import 'custom_variables/font-face';
@import 'layout/footer';
@import 'layout/header';
@import 'plugins/icomoon';
@import 'custom/checkbox-radio';
@import 'custom/tooltip';
@import 'react-redux-toastr/src/styles/index';

html {
  font-size: 81.3%;
  scroll-behavior: smooth;
  background: $white;

  @include media-breakpoint-up(md) {
    font-size: 87.5%;
  }

  @include media-breakpoint-up(xl) {
    font-size: 100%;
  }

  html[dir='ltr'] & {
    font-family: $font-family-sans-serif;
  }

  html[dir='rtl'] & {
    font-family: $font-family-arabic;
  }

  @include media-breakpoint-down(md) {
    .h3-md {
      font-size: 20px;
    }

    .h4-md {
      font-size: 15px;
    }
  }

  h6 {
    font-size: 16px;
  }
}

body {
  font-size: rem(16);

  html[dir='ltr'] & {
    text-align: left;
  }

  html[dir='rtl'] & {
    text-align: right;
  }
}

a {
  color: $primary;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

@each $name, $color in $grays {
  .text-gray-#{$name} {
    color: $color;
  }

  .bg-gray-#{$name} {
    background-color: $color;
  }
}

$fontsize: (
  'xxxs': 10px,
  'xxs': 11px,
  'xs': 12px,
  'sm': 13px,
  'normal': 14px,
  'md': rem(15),
  'base': rem(16),
  'lg': rem(18),
  'xl': rem(20),
  'xxl': rem(22),
  'xxxl': rem(24),
);

@each $name, $size in $fontsize {
  .font-#{$name} {
    font-size: $size !important;
  }
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

.font-weight-regular {
  font-weight: 400;
}

button:focus {
  outline: none;
}

.hide {
  display: none !important;
}

.top-0 {
  top: 0;
}

.left-0 {
  html[dir='ltr'] & {
    left: 0;
  }

  html[dir='rtl'] & {
    right: 0;
  }
}

.right-0 {
  html[dir='ltr'] & {
    right: 0;
  }

  html[dir='rtl'] & {
    left: 0;
  }
}

.bottom-0 {
  bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.img-cover {
  object-fit: cover;
}

.zIndex-0 {
  z-index: 0;
}

.zIndex-1 {
  z-index: 1;
}

.zIndex-9 {
  z-index: 9;
}

.zIndex-99 {
  z-index: 99;
}

.gutter {
  &-5 {
    margin-left: -5px !important;
    margin-right: -5px !important;

    & > [class^='col'] {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }

  &-8 {
    margin-left: -8px !important;
    margin-right: -8px !important;

    & > [class^='col'] {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }

  &-10 {
    margin-left: -10px !important;
    margin-right: -10px !important;

    & > [class^='col'] {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }

  &-12 {
    margin-left: -12px !important;
    margin-right: -12px !important;

    & > [class^='col'] {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px white inset !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

.list-unstyled {
  html[dir='rtl'] & {
    padding-right: 0;
  }
}

select {
  appearance: none;
  background: url(../images/dropdown.png) no-repeat;
  outline: 0;

  html[dir='ltr'] & {
    background-position: right 14px center;
    padding-right: 32px !important;
  }

  html[dir='rtl'] & {
    background-position: left 14px center;
    padding-left: 32px !important;
  }

  &.sm {
    background-size: 10px;

    html[dir='ltr'] & {
      background-position: right 7px center;
    }

    html[dir='rtl'] & {
      background-position: left 7px center;
    }
  }
}

.icon-flip-rtl {
  html[dir='rtl'] & {
    &::before {
      transform: scale(-1);
      display: inherit;
    }
  }
}

.tags {
  li {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.btn-360 {
  width: rem(50);
  height: rem(50);
}

.align-x-center {
  left: 50%;
  transform: translateX(-50%);
}

.align-y-center {
  top: 50%;
  transform: translateY(-50%);
}

.align-xy-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.letter-spacing {
  letter-spacing: 1px;

  &-sm {
    letter-spacing: 0.5px;
  }

  &-md {
    letter-spacing: rem(1.5);
  }

  &-lg {
    letter-spacing: rem(3.5);
  }
}

.overflow-auto {
  overflow: auto;
}

.list-dotted li {
  position: relative;
  margin-top: 10px;

  html[dir='ltr'] & {
    padding-left: 20px;
  }

  html[dir='rtl'] & {
    padding-right: 20px;
  }

  &:before {
    content: '';
    width: 5px;
    height: 5px;
    background-color: $gray-900;
    border-radius: 50%;
    position: absolute;
    top: 6px;

    html[dir='ltr'] & {
      left: 0;
    }

    html[dir='rtl'] & {
      right: 0;
    }
  }
}

.form-group {
  sup {
    color: #ff0000;
  }
}

.message-validation {
  color: #ff0000;
  font-size: 12px;

  @include media-breakpoint-up(xl) {
    position: absolute;
    bottom: 10px;
    transform: translateY(100%);

    html[dir='ltr'] & {
      left: 15px;
    }

    html[dir='rtl'] & {
      right: 15px;
    }
  }
}

.box-lg {
  max-width: 1006px;
}

.box-sm {
  max-width: 565px;
}

.box-xs {
  max-width: 340px;
}

.share-block {
  .round {
    min-width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .icon-twitter {
    color: #03a9f4;
  }

  .icon-facebook {
    color: #385c8e;
  }

  .icon-linkedin {
    color: #007aaa;
  }

  .instagram {
    background: radial-gradient(
      circle at 33% 100%,
      #fed373 4%,
      #f15245 30%,
      #d92e7f 62%,
      #9b36b7 85%,
      #515ecf
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

textarea.form-control {
  height: 116px;
  resize: none;
}

.phone-field {
  &.input-group > .form-control:not(:last-child) {
    max-width: 90px;
  }
}

.bg-gray-gradient {
  @include media-breakpoint-up(lg) {
    background: $white;
    background: -webkit-linear-gradient(left, $white 50%, $gray-100 50%);
    background: -o-linear-gradient(left, $white 50%, $gray-100 50%);
    background: linear-gradient(to right, $white 50%, $gray-100 50%);
  }
}

.caption {
  max-width: 767px;
  line-height: 20px;

  &-sm {
    max-width: 590px;
  }
}

.img-zoom {
  overflow: hidden;

  img {
    transition: all 0.5s ease-in-out;
  }

  &:hover {
    img {
      transform: scale(1.03);
    }
  }
}

.quick-access {
  &-col {
    width: 130px;
  }
  .quick-access-popup {
    box-shadow: 0px -2px 22px -6px rgba(0, 0, 0, 0.15);
    width: 278px;
    transform: translateY(100%);
    display: none;
    opacity: 0;
    transition: all 0.3s ease-in-out;

    li a {
      color: $gray-800;

      &:hover {
        color: $primary;
      }
    }
  }

  &.active {
    .quick-access-popup {
      display: block;
      opacity: 1;
    }
  }
}

.btn {
  i {
    position: relative;
    top: -1px;
  }
}

.modal.fullwidth-popup {
  .modal-dialog {
    max-width: 100%;
  }
}

.basic-single {
  .css-1laao21-a11yText {
    outline: none !important;
    border: none !important;
  }

  .css-6q0nyr-Svg {
    width: 17px;
    height: 17px;
    fill: $gray-800;
  }
}

.select__control {
  border-radius: $input-border-radius !important;
  border-color: $input-border-color !important;
  font-size: $input-font-size;
  height: 48px !important;
  box-shadow: none !important;

  &:focus {
    outline: none !important;
    border-color: $input-focus-border-color !important;
  }
}
.select__value-container {
  padding: 0 $input-padding-x !important;
}

.select__menu {
  font-size: 13px !important;
  text-transform: capitalize !important;
}

.btn-outline-light {
  border-color: $gray-500;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.react-tel-input .form-control {
  height: 49px !important;
}

.react-tel-input .flag-dropdown {
  border-radius: 0 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}
.login-popup {
  max-width: 572px !important;
  .account-outer {
    min-height: auto !important;
  }
}

.redux-toastr .top-left,
.redux-toastr .top-right,
.redux-toastr .top-center,
.redux-toastr .bottom-left,
.redux-toastr .bottom-right,
.redux-toastr .bottom-center {
  width: 100%;
  max-width: 450px;
  @include media-breakpoint-down(sm) {
      margin: 0 auto;
      transform: translateX(-50%)
  }
}

.redux-toastr {
  .toastr {
    background-color: $white !important;
    color: $gray-900 !important;
    svg {
      width: 24px !important;
      height: 24px !important;
    }
    &.rrt-error {
      svg {
        fill: $danger !important;
      }
    }
    &.rrt-success {
      svg {
        fill: $success !important;
        width: 18px !important;
        height: 18px !important;
      }
    }
    .rrt-title {
      font-size: 14px;
    }
    .rrt-text {
      font-size: 13px;
      color: $dark;
    }
  }
  .rrt-left-container {
    width: 50px !important;
    .rrt-holder {
      width: 20px !important;
      left: 20px !important;
    }
  }
  .rrt-middle-container {
    margin-left: 50px !important;
  }
}

.small-popup {
  max-width: 450px !important;
}

.select {
  [class*='-indicatorSeparator'] {
    display: none;
  }
}

.btn-outline-secondary-no-hover {
  color: #262626 !important;
  border-color: #262626;
}

.btn-primary-no-hover {
  color: #fff !important;
  background-color: #0078a0;
  border-color: #0078a0;
}

.close-icon{
  width: 50px;
  height: 50px;
}

.range-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-image: url(../images/range-input-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: $white;
  border: 1px solid $gray-500;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:focus{
    outline: 0;
  }
}

html[dir='rtl']{
  .react-tel-input{
    direction: ltr;
    * {
      direction: ltr;
      text-align: left;
    }
  }
}

.line-height-normal {
  line-height: normal;
}

.line-height-24 {
  line-height: 24px;
}

.disabled{
  pointer-events: none;
  opacity: 0.6;
}

.privay-content{
  h2{
    margin-bottom: rem(24);
  }
  p, ul, ol{
    font-size: 14px;
    line-height: 24px;
  }
  h5{
    margin: rem(30) 0 rem(24);
  }
}