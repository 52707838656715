@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?k2wkux');
  src:  url('../../fonts/icomoon.eot?k2wkux#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?k2wkux') format('truetype'),
    url('../../fonts/icomoon.woff?k2wkux') format('woff'),
    url('../../fonts/icomoon.svg?k2wkux#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-expand1 .path1:before {
  content: "\e93e";
  color: rgb(199, 197, 191);
}
.icon-expand1 .path2:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(137, 133, 124);
}
.icon-expand1 .path3:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(156, 154, 148);
}
.icon-expand1 .path4:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(177, 176, 172);
}
.icon-plus:before {
  content: "\e93d";
}
.icon-arrow-left-sm:before {
  content: "\e92d";
}
.icon-arrow-right-sm:before {
  content: "\e93c";
}
.icon-google1 .path1:before {
  content: "\e935";
  color: rgb(251, 189, 0);
}
.icon-google1 .path2:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(15, 157, 88);
}
.icon-google1 .path3:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(49, 170, 82);
}
.icon-google1 .path4:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(60, 121, 230);
}
.icon-google1 .path5:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(207, 45, 72);
}
.icon-google1 .path6:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(235, 65, 50);
}
.icon-facebook-rounded1:before {
  content: "\e936";
  color: #2196f3;
}
.icon-phone:before {
  content: "\e92c";
}
.icon-upload:before {
  content: "\e92b";
}
.icon-four-wheel-drive:before {
  content: "\e923";
}
.icon-two-wheel-drive:before {
  content: "\e924";
}
.icon-tick-round:before {
  content: "\e92a";
}
.icon-quick-access:before {
  content: "\e922";
}
.icon-expand:before {
  content: "\e925";
}
.icon-pause:before {
  content: "\e929";
}
.icon-location:before {
  content: "\e927";
}
.icon-book-service:before {
  content: "\e928";
}
.icon-search:before {
  content: "\e926";
}
.icon-slider:before {
  content: "\e921";
}
.icon-menu:before {
  content: "\e91f";
}
.icon-search-inventory:before {
  content: "\e920";
}
.icon-share:before {
  content: "\e91a";
}
.icon-test-drive:before {
  content: "\e900";
}
.icon-estimate-payment:before {
  content: "\e902";
}
.icon-360:before {
  content: "\e904";
}
.icon-add:before {
  content: "\e905";
}
.icon-arrow-left:before {
  content: "\e906";
}
.icon-arrow-right:before {
  content: "\e91e";
}
.icon-build-price:before {
  content: "\e907";
}
.icon-calculator:before {
  content: "\e908";
}
.icon-callback:before {
  content: "\e909";
}
.icon-chat:before {
  content: "\e90a";
}
.icon-close:before {
  content: "\e90b";
}
.icon-compare:before {
  content: "\e90c";
}
.icon-download:before {
  content: "\e90f";
}
.icon-info:before {
  content: "\e910";
}
.icon-info-sm:before {
  content: "\e911";
}
.icon-no-feature .path1:before {
  content: "\e912";
  color: rgb(241, 82, 73);
}
.icon-no-feature .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(173, 14, 14);
}
.icon-no-feature .path3:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-no-feature .path4:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(214, 214, 214);
}
.icon-no-feature .path5:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-quote:before {
  content: "\e918";
}
.icon-schedule:before {
  content: "\e919";
}
.icon-special-offers:before {
  content: "\e91b";
}
.icon-tick:before {
  content: "\e91c";
}
.icon-user:before {
  content: "\e91d";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-linkedin:before {
  content: "\e903";
}
.icon-chevron-down:before {
  content: "\e901";
}
.icon-chevron-up:before {
  content: "\e914";
}
.icon-chevron-left:before {
  content: "\e90d";
}
.icon-chevron-right:before {
  content: "\e90e";
}
