.footer-top {
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid $border-color;
    }

    .item {
        & + .item {
            html[dir="ltr"] & {
                border-left: 1px solid $border-color;
            }

            html[dir="rtl"] & {
                border-right: 1px solid $border-color;
            }

            @include media-breakpoint-up(xl) {
                .item-in {
                    html[dir="ltr"] & {
                        padding-left: 15px;
                    }

                    html[dir="rtl"] & {
                        padding-right: 15px;
                    }
                }
            }
        }

        i {
            font-size: 32px;

            @include media-breakpoint-down(xs) {
                font-size: 30px;
                width: 36px;
                display: inline-block;
            }
        }
    }
}

.footer-middle {
    ul li {
        a {
            color: $gray-800;

            &:hover {
                color: $primary;
            }
        }
    }
}

.footer-bottom {

    p,
    ul {
        font-size: 11px;

        @include media-breakpoint-down(md) {
            font-size: 10px;
        }
    }
}

.subscription {
    width: 340px;
    max-width: 100%;

    .btn-subscribe {
        padding-left: 20px;
        padding-right: 20px;
    }
}
