[type='checkbox']:not(:checked),
[type='checkbox']:checked {
    position: absolute;
    left: -9999px;
    display: none;
}

[type='checkbox']:not(:checked) + label,
[type='checkbox']:checked + label {
    position: relative;
    cursor: pointer;
    margin: 0;
    display: inline-flex !important;
    align-items: center;
    font-size: 13px;
    color: $gray-900;
    line-height: 17px;

    html[dir='ltr'] & {
        padding-left: 30px;
    }

    html[dir='rtl'] & {
        padding-right: 30px;
    }
}

[type='checkbox']:not(:checked) + label:before,
[type='checkbox']:checked + label:before {
    content: '';
    position: absolute;
    top: 0;
    width: 19px;
    height: 19px;

    html[dir='ltr'] & {
        left: 0;
    }

    html[dir='rtl'] & {
        right: 0;
    }
}

[type='checkbox']:not(:checked) + label:after,
[type='checkbox']:checked + label:after {
    position: absolute;
    content: "\e91c";
    font-family: icomoon;
    top: 2px;
    color: $white;
    font-size: 12px;

    html[dir='ltr'] & {
        left: 4px;
    }

    html[dir='rtl'] & {
        right: 4px;
    }
}

[type='checkbox']:not(:checked) + label:after {
    opacity: 0;
}

[type='checkbox']:checked + label:after {
    opacity: 1;
}

[type='checkbox']:not(:checked) + label:before {
    background-color: $white;
    border: 1px solid #DFDFDF;
}

[type='checkbox']:checked + label:before {
    border: 1px solid $primary;
    background-color: $primary;
}

input[type='radio'] {
    display: none;
}

input[type='radio'] + label {
    position: relative;
    cursor: pointer;
    font-size: rem(14);
    color: $gray-900;
    display: inline-flex !important;
    align-items: center;
    margin-bottom: 0;
    padding-top: rem(3);
    padding-bottom: rem(3);
    font-weight: $font-weight-semibold;
    html[dir='ltr'] & {
        padding-left: 33px;
    }

    html[dir='rtl'] & {
        padding-right: 33px;
    }

    &:before,
    &:after {
        position: absolute;
    }

    &:before {
        content: '';
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background: $gray-500;
        top: 0;
        display: block;
        transition: all ease 0.3s;

        html[dir='ltr'] & {
            left: 0;
        }

        html[dir='rtl'] & {
            right: 0;
        }
    }

    &:after {
        content: "\e91c";
        font-family: icomoon;
        border-radius: 50%;
        display: block;
        position: absolute;
        top: 0;
        transition: all ease 0.3s;
        color: $white;
        font-size: 11px;
        transform: rotate(-5deg);
        line-height: 24px;

        html[dir='ltr'] & {
            left: 6px;
        }

        html[dir='rtl'] & {
            right: 6px;
        }
    }
}

input[type='radio']:checked + label {
    &:before {
        background-color: $primary;
    }
}

.custom-checkbox,
.custom-radio {
    position: relative;

    [type='checkbox']:not(:checked) + label,
    [type='checkbox']:checked + label,
    [type='radio']:not(:checked) + label,
    [type='radio']:checked + label {
        position: relative;
        cursor: pointer;
        margin: 0;
        display: inline-flex !important;
        align-items: center;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.15);

        html[dir='ltr'] & {
            padding-left: 0;
        }

        html[dir='rtl'] & {
            padding-right: 0;
        }

        &:before {
            display: none;
        }

        &:after {
            position: absolute;
            font-family: icomoon;
            top: 50%;
            font-size: 13px;
            opacity: 1;
            transform: translate(-50%, -50%);
            width: auto;
            height: auto;
            background: transparent;
            left: 50% !important;
        }
    }

    [type='checkbox']:not(:checked) + label,
    [type='radio']:not(:checked) + label {
        background-color: $white;

        &:after {
            content: "\e905";
            color: $gray-900;
        }
    }

    [type='checkbox']:checked + label,
    [type='radio']:checked + label {
        background-color: $primary;

        &:after {
            content: "\e91c";
            color: $white;
        }
    }
    &.loading{
        pointer-events: none;
        [type='checkbox']:not(:checked) + label,
        [type='checkbox']:checked + label,
        [type='radio']:not(:checked) + label,
        [type='radio']:checked + label{
            &::before{
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                border: 2px solid $primary;
                border-right: 2px solid $white;
                border-bottom: 2px solid $white;
                border-radius: 50%;
                display: inline-block;
                animation: rotate 0.5s infinite linear;
                -webkit-animation: rotate 0.5s infinite linear;
                transform: rotate(0deg);
                z-index: 1;
                background-color: transparent;
            }
        }
    }
    &.disable{
        pointer-events: none;
    }
}

@keyframes rotate{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}
@-webkit-keyframes rotate{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.fill-radio {
    [type='radio'] + label {
        border: 1px solid $border-color;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        padding: 12px 12px !important;
        min-width: 70px;
        text-align: center;
        justify-content: center;

        &:before,
        &:after {
            display: none;
        }
    }
    [type='radio']:checked + label {
        background-color: $primary;
        border-color: $primary;
        color: $white;
    }
}

.switch {
    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;

    [type='checkbox'] + label {
        padding: 0 !important;
        color: $gray-700;
        @include media-breakpoint-down(xs) {
            font-size: 12px !important;
        }
        .switch-handle {
            background: $white;
            border-radius: 33px;
            box-shadow: 0px 0px 4px 2px rgba(203, 203, 203, 0.25);
            border: 1px solid #D2D2D2;
            padding: 0px !important;
            transition: all .4s ease;
            position: relative;
            outline: 0;
            display: block;
            width: 71px;
            min-width: 71px;
            height: 25px;
            cursor: pointer;
            user-select: none;
            margin: 0 15px;
            @include media-breakpoint-down(xs) {
                margin: 0 7px;
            }
            &:after {
                position: relative;
                display: block;
                content: '';
                width: 40px;
                height: 100%;
                top: 0;
                border-radius: 33px;
                background: $primary;
                transition: all .2s ease;
                opacity: 1;
            }
        }
    }

    [type='checkbox']:not(:checked) + label {
        .switch-handle:after {
            html[dir='ltr'] & {
                left: 0;
            }

            html[dir='rtl'] & {
                right: 0;
            }
        }
        .switch-before{
            color: $primary;
        }
    }

    [type='checkbox']:checked + label {
        .switch-handle:after {
            html[dir='ltr'] & {
                left: 29px;
            }

            html[dir='rtl'] & {
                right: 29px;
            }
        }
        .switch-after{
            color: $primary;
        }
    }

    [type='checkbox'] + label {
        font-size: 14px;

        &:before,
        &:after {
            display: none;
        }
    }
}

.simple-radio{
    input[type='radio'] + label {
        &::before{
            background-color: $white;
            border: 2px solid $gray-500;
        }
        &::after{
            content: '';
            width: 15px !important;
            height: 15px;
            width: 50%;
            border-radius: 50%;
            transform: scale(0);
            transform-origin: center;
            background-color: $primary;
            top: 4px;
            html[dir='ltr'] & {
                left: 4px;
            }
            html[dir='rtl'] & {
                right: 4px;
            }
        }
    }
    input[type='radio']:checked + label {
        &::before{
            border: 2px solid $primary;
        }
        &::after{
            transform: scale(1);
        }
    }
}