@import '../../../assets/sass/custom_variables/variables';

.FullLoader {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($dark, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 101;
  &__icon {
    width: 90px;
    height: 90px;
    background: $white;
    border-radius: 50%;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      width: 70px;
    }
  }
  &__text {
    color: $white;
    margin-top: rem(10);
  }
}

