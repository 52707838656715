$bootstrap-sass-asset-helper: false !default;
$browser-context: 16; // Default

@function rem($pixels, $context: $browser-context) {
    @return #{$pixels/$context}rem;
}

$container-max-widths: (
  sm: 1350px
);

$body-bg: #FFF;
$body-color: #2E2D2C;
$primary: #0078A0;
$secondary: #102B4E;
$white: #FFF;
$dark: #000;
$font-family-sans-serif: 'Ford Antenna', sans-serif;
$font-family-base: 'Ford Antenna', sans-serif;
$font-family-arabic: 'Ford Antenna', sans-serif;


$font-weight-lighter: 500;
$font-weight-light: 500;
$font-weight-normal: 500;
$font-weight-semibold: 500;
$font-weight-bold: 500;
$font-weight-bolder: 500;

$gray-100: #F3F3F3;
$gray-200: #F4F4F4;
$gray-300: #F7F7F7;
$gray-400: #E4E4E4;
$gray-500: #C4C4C4;
$gray-600: #494949;
$gray-700: #6E6E6E;
$gray-800: #1C1C1D;
$gray-900: #333333;

$border-color: #ECECEC;
$link-hover-color: $primary;

$h1-font-size: rem(42);
$h2-font-size: rem(32);
$h3-font-size: rem(26);
$h4-font-size: rem(24);
$h5-font-size: rem(20);
$h6-font-size: rem(16);

$display1-size: rem(82);
// $display2-size: rem(60);
// $display3-size: rem(40);
$display4-size: rem(30);

$headings-font-weight: $font-weight-bold;

$border-radius: 5px;
$border-radius-sm: 2px;
$border-radius-lg: 22px;

$input-border-radius: 0px;
$input-border-color: #CACACA;
$input-focus-border-color: $gray-600;
$input-font-size: 14px;
$input-padding-x : 0.875rem;
$input-btn-padding-y: 0.875rem;
$input-btn-font-size: 14px;
$input-btn-focus-color: $input-border-color;
$input-btn-focus-width: 0;
$input-color: $gray-900;
$input-placeholder-color: $gray-600;

$input-btn-font-size-sm: 11px;
$input-btn-padding-y-sm: 0.1875rem;
$input-btn-padding-x-sm: 0.75rem;

$btn-padding-x: 2.5rem;
$btn-border-radius: 0px;
$btn-border-radius-lg: 0px;
$btn-border-radius-sm: 0px;
$btn-font-weight: 500;
$btn-line-height: 1;

$dropdown-border-radius: 0;
$zindex-dropdown: 10000;
$enable-shadows: false;

$link-decoration: none;
// $link-hover-color: darken($link-color, 20%);
$link-hover-decoration: none;
$text-muted: $gray-700;

$footer-color: $white;
$footer-bg: $secondary;
$footer-hover-color: $primary;

$modal-md : 640px;