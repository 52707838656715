@font-face {
    font-family: 'Ford Antenna';
    src: url('../../fonts/FordAntenna-BlackCmp.woff2') format('woff2'),
        url('../../fonts/FordAntenna-BlackCmp.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ford Antenna';
    src: url('../../fonts/FordAntenna-Medium.woff2') format('woff2'),
        url('../../fonts/FordAntenna-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ford Antenna';
    src: url('../../fonts/FordAntenna-RegularXCnd.woff2') format('woff2'),
        url('../../fonts/FordAntenna-RegularXCnd.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Ford Antenna Medium Cnd';
//     src: url('FordAntenna-MediumCnd.woff2') format('woff2'),
//         url('FordAntenna-MediumCnd.woff') format('woff');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }
