header {
    z-index: 101;
    .logo-white {
        display: none;
    }
    @include media-breakpoint-up(lg) {
        .position-lg-static {
            position: static;
        }
        overflow-x: hidden;
    }

    @include media-breakpoint-down(md) {
        position: fixed;
        overflow: auto;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: all 0.3s ease-in-out;
        opacity: 0;
        visibility: hidden;
        transform: scale(0.9);
        background: $white;
        border-bottom: none !important;
        pointer-events: none;

        &.active {
            opacity: 1;
            visibility: visible;
            transform: scale(1);
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
            pointer-events: all;
        }

        .logo-block {
            border-bottom: 1px solid $border-color;
        }
    }

    .quick-links {
        border-bottom: 3px solid $border-color;

        .quick-link-icon {
            width: 32px;
            height: 32px;
            background: rgba($primary, .2);
        }
    }

    .social-media {
        border-top: 3px solid $border-color;
    }

    .main-menu {
        @include media-breakpoint-up(lg) {
            margin-left: -20px;
            margin-right: -20px;
        }
        @include media-breakpoint-down(md) {
            height: auto !important;
            width: 100%;
        }
        ul{
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            li{
                &:not(:first-child).is-current a{
                    color: $primary !important;
                }
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
        }
        ul > li, & > li {
            padding: 0 rem(20);

            & > a {
                font-size: rem(14);
                font-weight: $font-weight-semibold;
                letter-spacing: .5px;
                line-height: normal;
                padding-top: 2px;
                padding-bottom: 18px;
                color: $text-muted;
                html[dir="rtl"] &{
                    font-weight: 700;
                }
                &:hover {
                    color: $primary;
                }

                @media (max-width: 1400px) {
                    font-size: 12px;
                }

                @media (max-width: 1299.98px) {
                    letter-spacing: 0;
                }

                @include media-breakpoint-down(md) {
                    letter-spacing: 0.5px;
                }

                & > span {
                    position: relative;
                }
            }

            @media (max-width: 1299.98px) {
                padding: 0 rem(17);
            }

            @include media-breakpoint-down(md) {
                position: relative;

                & > a {
                    height: auto !important;
                    padding: rem(19) 0;
                    font-size: 16px;
                    color: $dark;
                }

                &.auth-link {
                    border-bottom: 1px solid $border-color;
                }
            }

            @include media-breakpoint-up(lg) {
                &:hover {
                    .mega-menu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                .mega-menu {
                    &.active{
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                        display: block !important;
                        top: 0;
                    }
                }
            }

            .mega-menu-dropdown {
                @include media-breakpoint-down(md) {
                    padding: rem(18) rem(16);
                }
            }

            .mega-menu {
                opacity: 0;
                visibility: hidden;
                top: calc(100% + 10px);
                transition: all 0.3s ease-in-out;
                min-height: 300px;
                position: absolute;
                z-index: 9;

                @include media-breakpoint-down(md) {
                    position: fixed;
                    box-shadow: none !important;
                    display: none !important;
                    top: 0;
                    height: 100%;
                    overflow: auto;
                }

                .sub-mega-menu {
                    width: calc(100% - 343px);
                    position: absolute;
                    top: 0;
                    transform: translateX(343px);
                    height: 100%;
                    background: $white;
                    display: none;

                    html[dir="ltr"] & {
                        left: 0;
                        transform: translateX(343px);
                    }

                    html[dir="rtl"] & {
                        right: 0;
                        transform: translateX(-343px);
                    }

                    @include media-breakpoint-down(md) {
                        display: none;
                    }

                    .sub-mega-menu-block {
                        width: 316px;

                        html[dir="ltr"] & {
                            border-right: 1px solid $border-color;
                        }

                        html[dir="rtl"] & {
                            border-left: 1px solid $border-color;
                        }
                    }
                }

                &.mega-menu-vehicle {
                    .vehicle-list {
                        width: 100%;

                        @include media-breakpoint-up(lg) {
                            max-width: 343px;
                        }

                        .vehicle-img {
                            width: rem(131);
                        }

                        & > ul {
                            & > li {
                                border-bottom: 1px solid $border-color;

                                @include media-breakpoint-up(lg) {
                                    html[dir="ltr"] & {
                                        border-right: 1px solid $border-color;
                                    }

                                    html[dir="rtl"] & {
                                        border-left: 1px solid $border-color;
                                    }

                                    &:last-child {
                                        border-bottom: none;
                                    }
                                }

                                @include media-breakpoint-up(lg) {
                                    &:first-child {
                                        .sub-mega-menu {
                                            display: block;
                                            z-index: 9;
                                        }
                                    }

                                    &:hover,
                                    &.active {
                                        .sub-mega-menu {
                                            display: block;
                                            z-index: 10;
                                        }

                                        & > a {
                                            background-color: $gray-100;
                                            font-weight: $font-weight-semibold;
                                            color: $primary;
                                        }
                                    }
                                }

                                & > a {
                                    transition: all 0.3s ease-in-out;
                                    padding: 4px 0;
                                    height: 74px;
                                    display: flex;

                                    @include media-breakpoint-down(md) {
                                        font-weight: 600;
                                    }

                                    & > div {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }

                &.mega-menu-online {
                    @include media-breakpoint-down(md) {
                        h2 {
                            font-size: 21px !important;
                        }

                        h6 {
                            font-size: 17px !important;
                            font-weight: 600 !important;
                        }
                    }

                    .online-steps {
                        & > li {
                            & > a {
                                background-color: $gray-100;
                                border-bottom: 1px solid $border-color;
                                min-height: 150px;
                                padding: 12px 15px;
                                margin-bottom: 20px;

                                @include media-breakpoint-down(md) {
                                    background-color: $primary;
                                    border-color: $primary;
                                    color: $white;
                                    margin-bottom: 8px;

                                    h5 {
                                        font-size: 14px !important;
                                    }
                                }

                                @include media-breakpoint-down(xs) {
                                    min-height: auto;
                                }
                            }

                            &:hover,
                            &.active {
                                & > a {
                                    background-color: $primary;
                                    border-color: $primary;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.header-mob {
    padding-top: 9px;
    padding-bottom: 9px;
}

// body{
//     @include media-breakpoint-up(lg) {
//         padding-top: 96px;
//     }   
// }