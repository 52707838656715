/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow{
    &.slick-disabled{
        opacity: 0.5;
        cursor: default;
    }
    &.slick-hidden {
        display: none;
    }
    position: absolute;
    top: 50%;
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
    border: 2px solid $white !important;
    overflow: hidden;
    z-index: 100;
    font-size: 0;
    transform: translateY(-50%);
    &:before {
        position: absolute;
        top: 16px;
        display: block;
        z-index: 11;
        font-family: 'icomoon' !important;
        opacity: 1 !important;
        font-size: 14px !important;
    }
    &.slick-prev{
        html[dir='ltr'] & {
            left:15px;
        }
        html[dir='rtl'] & {
            right:15px;
        }
        &:before{
            html[dir='ltr'] & {
                content: '\e92d';
                left:16px;
            }
            html[dir='rtl'] & {
                content: '\e93c';
                right:16px;
            }
        }
    }
    &.slick-next{
        html[dir='ltr'] & {
            right:15px;
        }
        html[dir='rtl'] & {
            left:15px;
        }
        &:before{
            html[dir='ltr'] & {
                content: '\e93c';
                right:16px;
            }
            html[dir='rtl'] & {
                content: '\e92d';
                left:16px;
            }
        }
    }
}

.slick-dots{
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    bottom: 30px !important;
    li{
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        button{
            font-size: 0;
            width: 8px !important;
            height: 8px !important;
            border-radius: 50%;
            background-color: $white !important;
            border: 0;
            padding: 0 !important;
            transition: all 0.3s ease-in-out;
            transform: scale(1);
            opacity: 0.4;

            &:before {
                display: none !important;
            }
        }
        &.slick-active{
            button{
                opacity: 1;
                transform: scale(1.7);
            }
        }
        &+li{
            margin-left: 6px;
        }
    }
}