@import "custom/common-import";
@import "plugins/slick";
@import "custom/tabs";

@include media-breakpoint-up(lg) {
    .headerHome {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: transparent;
        border-bottom: 1px solid rgba($border-color, 0.2) !important;
        &.headerFix{
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            background-color: $white;
            transform: translateY(-100%);
            transition: all 0.3s ease-in-out;
            animation: topAnimate 0.5s ease-in-out forwards;
            .main-menu > li > a, , .main-menu ul > li > a {
                color: $gray-700;
                padding-bottom: 0px;
                padding-top: 0px;
            }
        }
        @keyframes topAnimate {
            0%{transform: translateY(-100%)}
            100%{transform: translateY(0%)}
        }
        .logo {
            display: none;
        }

        .logo-white {
            display: block;
        }

        .main-menu > li > a, .main-menu ul > li > a {
            color: $white;
        }

        .header-top {
            color: $white;
            a {
                color: $white !important;
            }
        }
    }
}

.homepage-slider {
    @include media-breakpoint-up(lg) {
        margin-top: -96px;
        .slick-dots {
            display: none !important;
        }

        .banner-content {
            padding-top: 93px;
        }
    }

    @include media-breakpoint-down(md) {
        .display-1 {
            font-size: 37px;
        }
    }

    .bottom-block {
        label {
            font-size: 14px;
            @include media-breakpoint-down(md) {
                font-size: 16px;
            }
        }
        .text {
            font-size: rem(24);
        }
    }

    .banner-bg {
        img {
            object-fit: cover;
            @include media-breakpoint-up(lg) {
                min-height: 100vh;
            }

            @include media-breakpoint-down(md) {
                min-height: 540px;
            }
            @include media-breakpoint-down(sm) {
                min-height: 540px;
                max-height: 540px;
            }
        }
        
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: rem(380);
            background: linear-gradient(0deg, $dark -9.8%, rgba(0, 0, 0, 0) 81.06%);
        }
        @include media-breakpoint-up(lg) {
            &:after {
                content: '';
                background: linear-gradient(180deg, $dark -47.63%, rgba(0, 0, 0, 0) 85.32%);
                opacity: 0.2;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 193px;
            }
        }
    }
}

.desc-block {
    max-width: 820px;
    line-height: 22px;
}

.list {
    h6 {
        font-size: 16px !important;
    }

    @include media-breakpoint-down(md) {
        .list-link {
            font-size: 13px !important;
        }
    }
}

.shopping-tools-list {
    margin-left: rem(-16);
    margin-right: rem(-16);

    &::-webkit-scrollbar {
        height: 4px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba($gray-300, 1);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $gray-300;
    }

    @include media-breakpoint-down(lg) {
        margin-left: -15px;
        margin-right: -15px;

        &:before,
        &:after {
            content: '';
            width: 6px;
            min-width: 6px;
            height: 100%;
            position: relative;
            top: 0;
            background-color: transparent;
            min-height: 1px;
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }
    }

    .item {
        padding-left: rem(16);
        padding-right: rem(16);

        @include media-breakpoint-down(lg) {
            padding-left: 9px;
            padding-right: 9px;
        }

        @include media-breakpoint-down(md) {
            max-width: 278px;
        }
    }

    p {
        line-height: 20px;
    }
}

.home {
    @include media-breakpoint-down(md) {
        .main-title {
            font-size: 20px;
        }
    }
}

.social-media-images {
    .fig {
        &:after {
            content: '';
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
            height: 129px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    @include media-breakpoint-down(md) {
        ul {
            margin-left: rem(-8);
            margin-right: rem(-8);

            li {
                padding-left: rem(8);
                padding-right: rem(8);
            }
        }
    }
}

.select-model {
    width: 128px;
    [class*='-control'] {
      background-color: $gray-300;
      border-color: $gray-300;
      border-radius: 0;
      box-shadow: none;
      &:focus,
      &:hover,
      &:active {
        border-color: $gray-300;
        box-shadow: none;
      }
      .css-1uccc91-singleValue {
        font-size: rem(18);
      }
    }
}

.vehicle-tab{
    .nav-link{
        color: $text-muted;
        &.active{
            color: $gray-800;
        }
    }
}

.productBox {
    figure img {
        mix-blend-mode: multiply;
    }
    figcaption {
      .mainTitle {
        font-size: rem(46);
        opacity: 0.2;

        @include media-breakpoint-down(md) {
            font-size: 24px;
        }
      }

      h2 {
        font-size: rem(24);
        @include media-breakpoint-down(md) {
            font-size: 18px;
        }
      }

      p {
        font-size: 14px;
        @include media-breakpoint-down(md) {
          font-size: 13px;
        }
      }

      .subLogo {
        @include media-breakpoint-down(md) {
            max-width: 83px;
        }
      }
    }
}

.heritage-block {
    background-color: #000;

    @include media-breakpoint-up(sm) {
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(0deg, #000 12.49%, rgba(0, 0, 0, 0) 81.06%);
            height: rem(332);
            z-index: 1;
        }
    }

    img {
        opacity: 0.9;
        @include media-breakpoint-down(xs) {
            height: 637px;
        }
    }

    .content-block {
        max-width: 580px;

        @include media-breakpoint-down(xs) {
            max-width: 295px;
        }
    }
}

.build-price-block {
    // background-image: url(../images/build-price-banner.jpg);
    background-image: url(https://d31p3nq7mfltnl.cloudfront.net/public/uploads/assets/bg/build-price-banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    scroll-snap-type: y mandatory;
    background-position: center bottom;
    .build-price-slider {
        .slick-list{
            height: 100%;
            .slick-track{
                display: flex;
                height: 100%;
            }
        }
        figcaption {
            display: none;
        }
        @include media-breakpoint-up(lg) {
            .slick-dots {
                display: none !important;
            }
        }

        .slick-track {
            display: flex;
        }

        .slick-slide {
            height: inherit;

            &> div {
                height: 100%;
            }

            .productBox {
                height: 100%;
                figure{
                    img{
                        transform: scale(0.8);
                        transform-origin: bottom;
                        transition: all 0.4s linear 0.1s;
                        max-height: 100%;
                        max-height: 70vh;
                    }
                }
            }
        }

        .slick-active {
            figcaption {
                display: block;
            }
            .productBox {
                figure{
                    img{
                        transform: scale(1);
                    }
                }
            }
        }
    }
}

.subTitle {
    font-size: 28px;
    @include media-breakpoint-up(lg) {
        font-size: rem(46);
        line-height: rem(60);
    }
}

.extreme-slider {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 332px;
        left: 0px;
        bottom: 0;
        background: linear-gradient(0deg, #000000 12.49%, rgba(0, 0, 0, 0) 81.06%);
        opacity: 0.4;
    }
    .content-block {
        max-width: 655px;
    }

    .slick-track {
        display: flex;
    }

    .slick-slide {
        height: inherit;

        & > div {
            height: 100%;
        }

        img {
            min-height: 540px;
            opacity: 0.75;
            @include media-breakpoint-down(md) {
                opacity: 0.45;
            }
        }
    }
}

.slider-container {
    .slick-slide > div {
        display: flex;
    }
    .slick-arrow {
        @media only screen and (min-width: 1365px) {
            &.slick-prev {
                html[dir="ltr"] & {
                    left: calc((100vw - 1335px)/ 2);
                }
    
                html[dir="rtl"] & {
                    right: calc((100vw - 1335px)/ 2);
                }
            }
    
            &.slick-next {
                html[dir="ltr"] & {
                    right: calc((100vw - 1335px)/ 2);
                }
    
                html[dir="rtl"] & {
                    left: calc((100vw - 1335px)/ 2);
                }
            }
        }

        @include media-breakpoint-down(md) {
            display: none !important;
        }
    }
}

.home-gallery {
    .tab-underline {
        .nav-item .nav-link::before {
            background-color: $white;
        }
    }

    .img-block {
        height: rem(320);
        @include media-breakpoint-down(sm) {
            height: rem(200);
        }
    }

    .img-more {
        width: 25%;
        background-color: rgba(#0078A0, 0.57);
        height: rem(320);
        font-size: rem(24);
        pointer-events: none;

        @include media-breakpoint-up(md) {
            .icon-plus {
                font-size: rem(45);
            }
        }
        @include media-breakpoint-down(sm) {
            width: 50%;
            height: rem(200);
            font-size: 16px;
        }
    }
}

#SRLLightbox {
    button {
        border-radius: 50%;
    }

    .SRLPrevButton,
    .SRLNextButton {
        font-family: 'icomoon' !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        svg {
            display: none;
        }
    }

    .SRLPrevButton {
        div.SRLPrevButton {
            &:before {
                html[dir="ltr"] & {
                    content: "\e90d";
                }

                html[dir="rtl"] & {
                    content: "\e90e";
                }
            }
        }
    }

    .SRLNextButton {
        div.SRLNextButton {
            &:before {
                html[dir="ltr"] & {
                    content: "\e90e";
                }

                html[dir="rtl"] & {
                    content: "\e90d";
                }
            }
        }
    }
}