@import 'custom/common-import';
@import 'custom/tabs';
@import 'custom/tooltip';
@import 'plugins/slick';

.trim-content {
  line-height: 22px;
  ul{
    html[dir="ltr"] &{
      padding-left: rem(16);  
    }
    html[dir="rtl"] &{
      padding-left: 0;
      padding-right: rem(16);  
    }
  }
}
.trim-header {
  @include media-breakpoint-up(lg) {
    box-shadow: 0 5px 6px -6px rgba(0, 0, 0, 0.2);
  }
}
.trim-image {
  // padding-top: 50px;
  img {
    // max-width: 209px;
  }
  @include media-breakpoint-down(md) {
    min-height: 140px;
  }
}

.trim-level-products {
  background-color: $white;
  transition: all 0.3s ease-in-out;
  .trim-level-products-item {
    max-width: 315px;
  }
  @include media-breakpoint-up(lg) {
    &:hover {
      background-color: $gray-100;
    }

    &+.trim-level-products {
      html[dir="ltr"] & {
        border-left: 1px solid $gray-100;
      }
      html[dir="rtl"] & {
        border-right: 1px solid $gray-100;
      }
    }
  }
  @include media-breakpoint-down(md) {
    background: -moz-linear-gradient(top,  $white 23%, $white 23%, $gray-100 23%, $gray-100 23%, $gray-100 100%);
    background: -webkit-linear-gradient(top,  $white 23%,$white 23%,$gray-100 23%,$gray-100 23%,$gray-100 100%);
    background: linear-gradient(to bottom,  $white 23%,$white 23%,$gray-100 23%,$gray-100 23%,$gray-100 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$white', endColorstr='$gray-100',GradientType=0 );

    &:not(.active) {
      display: none;
    }
    .btn-trim {
      background-color: $primary;
      color: $white;
      padding: 12px 17px 10px 17px;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 9;
    }
  }
}

.trim-footer {
  @include media-breakpoint-down(md) {
    position: fixed;
    .btn-link {
      background-color: $gray-300;
    }
  }
}

.compare-popup {
  .modal-dialog {
    max-width: none;
  }
}

.row-compare {
  @include media-breakpoint-down(md) {
    background-color: $white;
  }
  @media (max-width: 425px) {
    display: table;
  }
  .compare-car {
    min-width: 220px;
    max-width: 220px;
    html[dir='ltr'] & {
      border-right: 2px solid $white;
    }
    html[dir='rtl'] & {
      border-left: 2px solid $white;
    }
    img {
      max-height: 78px;
    }
    @include media-breakpoint-down(lg) {
      min-width: 126px;
      max-width: 126px;
    }
    @include media-breakpoint-down(md) {
      // min-width: 106px;
      // max-width: 106px;
      min-width: 106px;
      max-width: inherit;
      display: table-cell;
    }
  }
  .compare-carfeature {
    min-width: 220px;
    max-width: 220px;
    @include media-breakpoint-down(lg) {
      min-width: 126px;
      max-width: 126px;
    }
    @include media-breakpoint-down(md) {
      // min-width: 106px;
      // max-width: 106px;
      min-width: 106px;
      max-width: inherit;
      border-right: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      display: table-cell;
      &:last-child {
        border-right: 0;
      }
    }
  }
}

.compare-carfeature-row {
  &:nth-child(even) {
    background-color: $gray-200;
  }
  @include media-breakpoint-down(lg) {
    background-color: $gray-200;
  }
}

.compare-tabContent {
  @media (max-width: 425px) {
    .row {
      display: table;
    }
  }
}

.model-select{
  &-outer {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($white, 0.75);
    }
  }

  &-content-wrapper {
    background-repeat: no-repeat;
    background-size: 800px;
    html[dir="ltr"] & {
      background-position: top 00px right;
    }
    html[dir="rtl"] & {
      background-position: top 0px left;
    }
  }
}

.model-select-content-wrapper, .model-select-outer{
  min-height: 489px;
}

.product-slider{
  .slick-track{
    margin-left: 0;
  }
  .slick-slide{
    &+.slick-slide {
      html[dir="ltr"] & {
        border-left: 1px solid $gray-100;
      }
      html[dir="rtl"] & {
        border-right: 1px solid $gray-100;
      }
    }
  }
  .slick-arrow{
    width: 45px;
    height: 45px;
    background-color: $white !important;
    opacity: 1 !important;
  }
}

.trim-level-year{
  @include media-breakpoint-up(lg) {
    .trim-level-year-btn{
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }
    &:hover{
      .trim-level-year-btn{
        opacity: 1;
        visibility: visible;
      } 
    }
  }
}

.compare-slider{
  .slick-track{
    margin: 0;
  }
  .slick-prev, .slick-next{
    width: 30px !important;
    height: 30px !important;
    top: 83px;
    border: 0 !important;
    background-color: $white !important;
    &::before{
      font-family: 'icomoon' !important;
      text-indent: 0;
      font-size: rem(20);
      color: $black;
      border: 0 !important;
      transform: none !important;
      width: 30px !important;
      height: 30px !important;
      top: 0 !important;
      left: 0 !important;
      margin: 0 !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }
  .slick-prev{
    &::before{
      content: "\e906" !important;
    }
    @include media-breakpoint-down(xs) {
      html[dir='ltr'] & {
        left: 0px;
      }
      html[dir='rtl'] & {
        right: 0px;
      }
    }
  }
  .slick-next{
    &::before{
      content: "\e91e" !important;
    }
    @include media-breakpoint-down(xs) {
      html[dir='ltr'] & {
        right: 0px;
      }
      html[dir='rtl'] & {
        left: 0px;
      }
    }
  }
}

.compare-val{
  b{
    font-size: 13px;
    font-weight: $font-weight-bold;
    color: $primary;
  }
}

.trim-bg{
  &::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    max-height: 202px;
    background-color: $gray-300;
  }
}

.btn-trim {
  padding: 6px 17px 4px 17px;
  background-color: $gray-100;
  &:hover {
    background-color: $primary;
    color: $white;
  }
}

.compare-brand-image{
  height: 80px;
  img{
    max-height: 80px;
  }
}